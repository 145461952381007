'use client'

import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'

import { config } from '@/_config'

if (config.POSTHOG_ENABLED && typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
    person_profiles: 'identified_only',
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
    capture_pageleave: true,
    debug: config.POSTHOG_DEBUG,
  })
}

export function PHProvider({ children }: { children: React.ReactNode }) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}
